/* @noflow */
import React, {useState, useEffect} from 'react';
import {css} from '@emotion/core';
import {logDplWithExtensionParams, EVENTS} from '../util/dpl';

import Layout from '../components/Layout.tsx';
import SEO from '../components/Seo.tsx';
import {getAppStoreParams} from '../util/extensionMetadata';

const UninstallPage = ({experimentId}) => {
    const [hasFiredPageVisit, setHasFiredPageVisit] = useState(false);

    useEffect(() => {
        const appStoreParams = getAppStoreParams() || {};

        if (!hasFiredPageVisit) {
            logDplWithExtensionParams(EVENTS.MapquestExtensionUninstallPageVisit, appStoreParams).then(() => {
                window.location.href = 'https://mapquest.com';
            });
            setHasFiredPageVisit(true);
        }
    }, [experimentId, hasFiredPageVisit]);

    return (
        <Layout>
            <SEO title="Successfully Uninstalled Mapquest Extension" />
            <div
                css={css`
                    padding: 1rem;
                `}
            >
                loading...
            </div>
        </Layout>
    );
};

export default UninstallPage;
